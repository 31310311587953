const CONTENT_EVENT_NAMES = { VIRTUAL_PAGE_VIEW_EVENT: "gtm.historyChange" };

export const trackVirtualPageView = (pagePath: string) => {
  window.dataLayer.push({ event: CONTENT_EVENT_NAMES.VIRTUAL_PAGE_VIEW_EVENT, pagePath });
};

export function trackUAEvent(category: string, action: string, label: string | number = 0) {
  window.dataLayer.push({
    event: "GAEvent",
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventValue: 0,
    nonInteraction: 0,
  });
}

import { getFormDataJson } from "@shared/Utils/form-utils";
import { setupUnobtrusiveValidation, validateForm } from "./input-validation";
import { hideElement, showElement } from "./input-validation-helpers";
import { isEmptyString } from "@shared/Utils";
import { sendGALeadFormEvent, sendGANewsLetterEvent } from "@shared/Analytics/content/form-analytics";
import { trackUAEvent } from "@shared/Analytics/content/content-analytics";
import { isPathInUrl } from "@shared/Utils/analytics-utils";

interface SendReceiptResult {
  title: string;
  content: string;
  isError: boolean;
}

const remapErrorMessage = (leadForm: HTMLFormElement): void => {
  const textField2 = leadForm.querySelector<HTMLElement>("[name='InputTextField2']");
  if (!textField2) return;

  const label = leadForm.querySelector<HTMLElement>(`label[for='${textField2.id}']`);
  textField2.setAttribute(
    "data-val-required",
    `Du må fylle ut ${label?.innerText.replace(/\*/g, "").trim().toLowerCase()}`,
  );
};

const trackSubmitEvent = (form: HTMLFormElement) => {
  const { analyticsCategory = "", analyticsAction = "", analyticsLabel } = form.dataset;
  trackUAEvent(analyticsCategory, analyticsAction, analyticsLabel);

  /* GA4 */
  if (isPathInUrl("/nyhetsbrev")) return sendGANewsLetterEvent();

  sendGALeadFormEvent(analyticsCategory.toLowerCase(), analyticsAction);
};

const setIcon = (container: Element | null, showError: boolean) => {
  if (!container || !showError) return;

  container.classList.add("form-receipt__icon-error");
  container.innerHTML =
    '<svg style="width:4.8em;height:4.8em" focusable="false" aria-hidden="true"><use xlink:href="#ice-warning"></use></svg>';
};

const setText = (el: HTMLElement | null, text: string) => {
  if (!el || isEmptyString(text)) return;

  el.innerHTML = text;
  showElement(el);
};

const LoadResponseMessage = (apiUrl: string, form: HTMLFormElement, receiptContainer: HTMLElement) => {
  const submitBtn = form.querySelector<HTMLButtonElement>("button");

  return async (event: Event) => {
    event.preventDefault();

    if (!validateForm(form)) return;

    const title = receiptContainer.querySelector<HTMLElement>("[name='form-receipt__title']");
    const content = receiptContainer.querySelector<HTMLElement>("[name='form-receipt__message']");
    const screenReaderMessage = receiptContainer.querySelector<HTMLElement>("[name='form-receipt-screenreader']");
    const icon = receiptContainer.querySelector<HTMLElement>("[name='form-receipt__icon']");

    submitBtn?.classList.add("btn--loading");

    const res = await fetch(apiUrl, {
      method: "POST",
      body: JSON.stringify(getFormDataJson(form)),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });

    submitBtn?.classList.remove("btn--loading");

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    const data = (await res.json()) as SendReceiptResult;

    hideElement(form);

    setIcon(icon, data.isError);
    setText(title, data.title);
    setText(content, data.content);
    setText(screenReaderMessage, `${data.title} ${data.content}`);

    showElement(receiptContainer);

    trackSubmitEvent(form);
  };
};

const appendLeadFormEvents = (formContainer: HTMLElement) => {
  const form = formContainer.querySelector<HTMLFormElement>("form");
  if (!form) {
    console.error("No <form> element found for simple lead form");
    return;
  }

  remapErrorMessage(form);
  setupUnobtrusiveValidation(form);

  const apiUrl =
    form.getAttribute("name") === "interestLeadForm" ? "/interestleadform/SendLead" : "/SimpleLeadFormBlock/SendLead";

  const receiptContainer = formContainer.querySelector<HTMLElement>(".js-receipt");

  if (!receiptContainer) {
    console.error("No receipt containers found for simple lead form");
    return;
  }
  form.addEventListener("submit", LoadResponseMessage(apiUrl, form, receiptContainer));
};

document.addEventListener("DOMContentLoaded", function () {
  const interestFormContainers = document.querySelectorAll<HTMLElement>(".js-interest-form");

  if (!interestFormContainers?.length) {
    console.warn("No containers found for simple lead form");
    return;
  }

  for (const container of interestFormContainers) {
    appendLeadFormEvents(container);
  }
});
